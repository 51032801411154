define("web-chat/serializers/event", ["exports", "web-core/serializers/event"], function (_exports, _event) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _event.default;
    }
  });
});