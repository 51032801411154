define("web-chat/initializers/queue-host-events", ["exports", "web-chat/services/ipc", "ember-window-mock"], function (_exports, _ipc, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.teardown = teardown;
  _exports.initialize = initialize;
  _exports.default = void 0;

  var messageHandler = function messageHandler(ev) {
    _ipc.eventQueue.push(ev);
  };

  function teardown() {
    _ipc.eventQueue.clear();

    _emberWindowMock.default.removeEventListener('message', messageHandler, false); // @ts-ignore


    delete _emberWindowMock.default.wkSmoopePostMessage;
  }

  function initialize() {
    _ipc.eventQueue.clear();

    _emberWindowMock.default.addEventListener('message', messageHandler, false); // handler for wkwebview shim


    _emberWindowMock.default.wkSmoopePostMessage = function (json) {
      try {
        var event = {
          data: JSON.stringify(json)
        };
        messageHandler(event);
        var messageEvent = new MessageEvent('message', {
          data: event.data,
          origin: _emberWindowMock.default.location.origin
        });

        _emberWindowMock.default.dispatchEvent(messageEvent);
      } catch (_unused) {
        console.warn("unable to handle webkit host message: ".concat(json));
      }
    };
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});