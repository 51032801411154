define("web-chat/services/can", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-can/services/can"], function (_exports, _slicedToArray2, _can) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Custom ember-can service that overwrites parsing to:
   *
   * can('user.canManage')
   *
   * instead of
   *
   * can('manage user')
   *
   */
  var _default = _can.default.extend({
    parse: function parse(str) {
      var _str$split = str.split('.'),
          _str$split2 = (0, _slicedToArray2.default)(_str$split, 2),
          abilityName = _str$split2[0],
          propertyName = _str$split2[1];

      return {
        propertyName: propertyName,
        abilityName: abilityName
      };
    }
  });

  _exports.default = _default;
});