define("web-chat/components/input-tools/emoji", ["exports", "web-core/components/input-tools/emoji"], function (_exports, _emoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emoji.default;
    }
  });
});