define("web-chat/utils/store/localstorage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LocalStorage = {
    setItem: function setItem(key, data) {
      return localStorage.setItem(key, data);
    },
    getItem: function getItem(key) {
      return localStorage.getItem(key);
    },
    removeItem: function removeItem(key) {
      return localStorage.removeItem(key);
    },
    clear: function clear() {
      return localStorage.clear();
    }
  };
  var _default = LocalStorage;
  _exports.default = _default;
});