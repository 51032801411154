define("web-chat/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2cbCfe+4",
    "block": "[[[10,0],[14,0,\"ember-view\"],[12],[46,[28,[37,1],null,null],null,null,null],[1,\" \"],[41,[30,0,[\"theme\",\"style\"]],[[[10,\"style\"],[12],[1,[30,0,[\"theme\",\"style\"]]],[13]],[]],null],[41,[30,0,[\"knowledge\",\"style\"]],[[[10,\"style\"],[12],[1,[30,0,[\"knowledge\",\"style\"]]],[13]],[]],null],[1,\" \"],[8,[39,3],null,[[\"@name\"],[\"dnd-overlay\"]],null],[13]],[],false,[\"component\",\"-outlet\",\"if\",\"from-elsewhere\"]]",
    "moduleName": "web-chat/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});