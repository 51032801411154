define("web-chat/templates/components/empty-events-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8+6dhTfh",
    "block": "[[[10,0],[14,0,\"room-empty\"],[12],[10,0],[14,0,\"room-empty__icon\"],[12],[1,[28,[35,0],[\"icon-support-agent\"],null]],[13],[1,\" \"],[10,0],[14,0,\"room-empty__title\"],[12],[1,[28,[35,1],[\"room.empty.title\"],null]],[13],[1,\" \"],[10,0],[14,0,\"room-empty__description\"],[12],[1,[28,[35,1],[\"room.empty.description\"],null]],[13],[13]],[],false,[\"svg-jar\",\"t\"]]",
    "moduleName": "web-chat/templates/components/empty-events-log.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});