define("web-chat/templates/components/message-action/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o92j3VL1",
    "block": "[[[8,[39,0],null,[[\"@forType\",\"@readonly\"],[\"select\",[30,1]]],[[\"default\"],[[[[11,\"form\"],[24,0,\"form\"],[4,[38,1],[\"submit\",[28,[37,2],[[28,[37,3],null,null],[28,[37,4],[[30,2],[30,0,[\"selectedValue\"]]],null]],null]],null],[4,[38,5],[[30,0,[\"didInsert\"]]],null],[12],[10,0],[14,0,\"form-field--with-addon\"],[12],[8,[39,6],[[24,0,\"message-action-select\"]],[[\"@value\",\"@disabled\",\"@required\",\"@options\",\"@optionTargetPath\",\"@optionLabelPath\",\"@optionValuePath\",\"@includeBlank\",\"@prompt\",\"@update\"],[[30,0,[\"selectedValue\"]],[30,1],[30,3,[\"required\"]],[30,3,[\"options\"]],\"value\",\"label\",\"value\",true,[28,[37,7],[\"actions.choose_option\"],null],[28,[37,4],[[28,[37,8],[[30,0,[\"selectedValue\"]]],null]],null]]],null],[1,\" \"],[10,0],[14,0,\"form-field__addon\"],[12],[10,\"button\"],[14,0,\"button button--icon button--primary\"],[15,\"disabled\",[52,[30,1],\"disabled\"]],[14,4,\"submit\"],[12],[41,[30,4],[[[1,[28,[35,10],[\"icon-checkmark\"],null]]],[]],[[[1,[28,[35,10],[\"icon-angle-right\"],null]]],[]]],[13],[13],[13],[13]],[]]]]]],[\"@readonly\",\"@invokeAction\",\"@action\",\"@invokedBy\"],false,[\"message-action-base\",\"on\",\"queue\",\"prevent-default\",\"fn\",\"did-insert\",\"one-way-select\",\"t\",\"mut\",\"if\",\"svg-jar\"]]",
    "moduleName": "web-chat/templates/components/message-action/select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});