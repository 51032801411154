define("web-chat/templates/components/message-action/textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rG7G0VvG",
    "block": "[[[8,[39,0],null,[[\"@forType\",\"@readonly\"],[\"textarea\",[30,1]]],[[\"default\"],[[[[11,\"form\"],[24,0,\"form\"],[4,[38,1],[\"submit\",[28,[37,2],[[28,[37,3],null,null],[28,[37,4],[[30,2],[30,0,[\"value\"]]],null]],null]],null],[4,[38,5],[[30,0,[\"didInsert\"]]],null],[12],[10,0],[14,0,\"form-field\"],[12],[41,[28,[37,7],[[30,1],[30,3]],null],[[[10,\"textarea\"],[15,2,[30,3,[\"content\",\"value\"]]],[14,\"readonly\",\"\"],[12],[13]],[]],[[[8,[39,8],[[16,\"required\",[30,4,[\"required\"]]],[16,\"placeholder\",[30,4,[\"label\"]]],[16,\"readonly\",[30,1]]],[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\"]],[]]],[1,\" \"],[10,\"button\"],[14,0,\"button button--primary\"],[15,\"disabled\",[52,[30,1],\"disabled\"]],[14,4,\"submit\"],[12],[1,[28,[35,9],[\"actions.confirm\"],null]],[13],[13],[13]],[]]]]]],[\"@readonly\",\"@invokeAction\",\"@invokedBy\",\"@action\"],false,[\"message-action-base\",\"on\",\"queue\",\"prevent-default\",\"fn\",\"did-insert\",\"if\",\"and\",\"textarea\",\"t\"]]",
    "moduleName": "web-chat/templates/components/message-action/textarea.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});