define("web-chat/formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    time: {
      hhmmss: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
    },
    date: {
      mmmm: {
        month: 'long'
      },
      yyyy: {
        year: 'numeric'
      },
      yymmdd: {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
      },
      hhmmss: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      },
      yymmddhhmm: {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric'
      }
    },
    number: {
      EUR: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  };
  _exports.default = _default;
});