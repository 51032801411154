define("web-chat/session-stores/application", ["exports", "ember-simple-auth/session-stores/cookie", "ember-simple-auth/session-stores/ephemeral", "ember-simple-auth/session-stores/local-storage", "web-core/utils/supports"], function (_exports, _cookie, _ephemeral, _localStorage, _supports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BaseStore = _ephemeral.default;

  if ((0, _supports.default)('cookies')) {
    BaseStore = _cookie.default;
  } else if ((0, _supports.default)('localStorage')) {
    BaseStore = _localStorage.default;
  }

  var _default = BaseStore.extend({
    sameSite: window.location.protocol === 'https:' ? 'None' : 'Lax',
    cookieName: 'smoope-web-chat',
    key: 'smoope-web-chat'
  });

  _exports.default = _default;
});