define("web-chat/utils/mimes", ["exports", "web-core/utils/mimes"], function (_exports, _mimes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_mimes).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _mimes[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _mimes[key];
      }
    });
  });
});