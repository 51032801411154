define("web-chat/utils/focus-if-writeable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.focusIfWriteable = focusIfWriteable;

  // TODO: rewrite this to a modifier if possible
  function focusIfWriteable(element, selector) {
    if (typeof selector !== 'string') return;
    var focusTarget = // @ts-ignore
    element.querySelector(selector);
    if (!focusTarget) return;
    if (!document.activeElement) return; // if document active element is already a message action, don't focus this

    var activeElement = document.activeElement.closest('.event-type--s-message-action:not(.event-type--validation-success)');
    if (activeElement) return; // we schedule this afterRender to avoid any update in the same computation
    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions

    Ember.run.scheduleOnce('afterRender', null, function () {
      focusTarget.focus();
    });
  }
});