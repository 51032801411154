define("web-chat/initializers/inflector", ["exports", "web-core/initializers/inflector"], function (_exports, _inflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _inflector.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _inflector.initialize;
    }
  });
});