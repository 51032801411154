define("web-chat/components/message-action/text", ["exports", "web-chat/components/message-action/-private/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  var _exportNames = {};
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _text.default;
    }
  });
  Object.keys(_text).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in _exports && _exports[key] === _text[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _text[key];
      }
    });
  });
});