define("web-chat/initializers/async-promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    // this is required to make regenerator async/await use rsvp which in turn is runloop aware
    window.Promise = Ember.RSVP.Promise;
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});