define("web-chat/utils/object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEqual = isEqual;

  function isEqual(a, b) {
    try {
      return JSON.stringify(a) === JSON.stringify(b);
    } catch (_unused) {
      return false;
    }
  }
});