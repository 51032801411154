define("web-chat/templates/components/default-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zc0uTNei",
    "block": "[[[10,0],[12],[10,0],[15,0,[29,[\"flash-message__head \",[52,[30,1,[\"omitTitle\"]],\"flash-message__head--no-title\"]]]],[12],[10,0],[14,0,\"flash-message__title\"],[12],[41,[30,1,[\"title\"]],[[[1,[28,[35,1],[[30,1,[\"title\"]]],null]]],[]],[[[1,[28,[35,1],[[28,[37,2],[\"flash.type.\",[30,1,[\"type\"]]],null]],null]]],[]]],[13],[1,\" \"],[41,[51,[30,1,[\"unclosable\"]]],[[[11,0],[24,0,\"flash-message__close\"],[24,\"role\",\"button\"],[4,[38,4],[\"click\",[30,2]],null],[12],[1,[28,[35,5],[\"icon-remove\"],null]],[13]],[]],null],[13],[1,\" \"],[10,0],[14,0,\"flash-message__body\"],[12],[10,0],[14,0,\"flash-message__message\"],[12],[1,[28,[35,1],[[30,1,[\"message\"]]],null]],[13],[13],[13]],[\"@flash\",\"@close\"],false,[\"if\",\"t\",\"concat\",\"unless\",\"on\",\"svg-jar\"]]",
    "moduleName": "web-chat/templates/components/default-flash.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});