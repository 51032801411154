define("web-chat/templates/components/autosize-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ozZ/nXkc",
    "block": "[[[8,[39,0],[[24,0,\"custom-scrollbars\"],[24,1,\"the-input\"],[24,\"rows\",\"1\"],[16,\"placeholder\",[30,1]],[4,[38,1],[[30,0,[\"didInsertTextarea\"]]],null],[4,[38,2],[[30,0,[\"willDestroyTextarea\"]]],null],[4,[38,3],[\"focusout\",[30,3]],null],[4,[38,3],[\"focusin\",[30,4]],null],[4,[38,3],[\"keydown\",[30,0,[\"onKeyDown\"]]],null],[4,[38,3],[\"input\",[30,5]],null]],[[\"@value\"],[[30,2]]],null]],[\"@placeholder\",\"@value\",\"@onFocusOut\",\"@onFocusIn\",\"@textDidChange\"],false,[\"textarea\",\"did-insert\",\"will-destroy\",\"on\"]]",
    "moduleName": "web-chat/templates/components/autosize-textarea.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});