define("web-chat/utils/emoji", ["exports", "web-core/utils/emoji"], function (_exports, _emoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "GROUPS", {
    enumerable: true,
    get: function get() {
      return _emoji.GROUPS;
    }
  });
  Object.defineProperty(_exports, "EMOJIS", {
    enumerable: true,
    get: function get() {
      return _emoji.EMOJIS;
    }
  });
  Object.defineProperty(_exports, "ALIAS_UNICODE", {
    enumerable: true,
    get: function get() {
      return _emoji.ALIAS_UNICODE;
    }
  });
  Object.defineProperty(_exports, "aliasToUnicode", {
    enumerable: true,
    get: function get() {
      return _emoji.aliasToUnicode;
    }
  });
  Object.defineProperty(_exports, "unicodeToImage", {
    enumerable: true,
    get: function get() {
      return _emoji.unicodeToImage;
    }
  });
  Object.defineProperty(_exports, "shortNameToUnicode", {
    enumerable: true,
    get: function get() {
      return _emoji.shortNameToUnicode;
    }
  });
});