define("web-chat/utils/promise", ["exports", "web-core/utils/promise"], function (_exports, _promise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "retry", {
    enumerable: true,
    get: function get() {
      return _promise.retry;
    }
  });
});