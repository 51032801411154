define("web-chat/utils/css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fontFamily = fontFamily;
  var fontNode = document.createElement('div');

  function fontFamily(value, defaultValue) {
    var _fontNode$style$fontF;

    fontNode.style.fontFamily = '';
    fontNode.style.fontFamily = value;
    return (_fontNode$style$fontF = fontNode.style.fontFamily) !== null && _fontNode$style$fontF !== void 0 && _fontNode$style$fontF.length ? fontNode.style.fontFamily : defaultValue;
  }
});