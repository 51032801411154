define("web-chat/utils/supports", ["exports", "web-core/utils/supports"], function (_exports, _supports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "reset", {
    enumerable: true,
    get: function get() {
      return _supports.reset;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _supports.default;
    }
  });
});