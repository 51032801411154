define("web-chat/components/message-action-base", ["exports", "web-core/components/message-action-base"], function (_exports, _messageActionBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _messageActionBase.default;
    }
  });
});