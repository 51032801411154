define("web-chat/utils/form-markup", ["exports", "dompurify", "web-core/utils/markup", "web-chat/utils/downa"], function (_exports, _dompurify, _markup, _downa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.serializeFormForHost = serializeFormForHost;

  function render(text) {
    if (typeof text !== 'string') return '';
    return (0, _downa.default)(_dompurify.default.sanitize((0, _markup.htmlEntities)(text), {
      ALLOWED_TAGS: ['BR']
    }));
  }

  function serializeFormForHost(form) {
    if (!form) return;
    var formData = // @ts-ignore
    form.serialize({
      includeId: true
    }).data;
    var fields = formData.attributes.fields;
    fields.forEach(function (field) {
      field._label.de = render(field._label.de);
      field._label.en = render(field._label.en);
      field._label.fr = render(field._label.fr);
      field._label.it = render(field._label.it);
      field._label.nl = render(field._label.nl);
    });
    return formData;
  }
});