define("web-chat/templates/components/dnd-file-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g1vh7zGl",
    "block": "[[[8,[39,0],null,[[\"@id\",\"@disabled\",\"@loadingAllowed\",\"@filesLoaded\",\"@filesRejected\"],[[30,1],[30,2],[30,0,[\"loadingAllowed\"]],[30,3],[30,4]]],null],[1,\" \"],[11,0],[16,0,[29,[\"dnd-file-loader \",[52,[30,0,[\"hasDraggedOver\"]],\"dnd-file-loader--hover\"],\" \",[52,[30,0,[\"hasDraggedIn\"]],\"dnd-file-loader--in\"]]]],[24,\"role\",\"button\"],[4,[38,2],[[30,0,[\"setupLoader\"]]],null],[4,[38,3],[[30,0,[\"teardownLoader\"]]],null],[4,[38,4],[\"dragenter\",[30,0,[\"onOverlayDragEnter\"]]],null],[4,[38,4],[\"drop\",[30,0,[\"onOverlayDrop\"]]],null],[4,[38,4],[\"dragover\",[30,0,[\"onOverlayDragOver\"]]],null],[4,[38,4],[\"dragleave\",[30,0,[\"onOverlayDragLeave\"]]],null],[12],[10,0],[14,0,\"dnd-file-loader_content\"],[12],[10,0],[14,0,\"dnd-file-loader__body\"],[12],[1,[28,[35,5],[\"icon-upload\"],[[\"class\"],[\"dnd-file-loader__icon\"]]]],[1,\" \"],[10,0],[14,0,\"dnd-file-loader__title\"],[12],[1,[28,[35,6],[\"documents.upload.title\"],null]],[13],[13],[13],[13]],[\"@loaderId\",\"@disabled\",\"@filesLoaded\",\"@filesRejected\"],false,[\"file-loader\",\"if\",\"did-insert\",\"will-destroy\",\"on\",\"svg-jar\",\"t\"]]",
    "moduleName": "web-chat/templates/components/dnd-file-loader.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});