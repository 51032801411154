define("web-chat/initializers/paginations", ["exports", "web-core/initializers/paginations"], function (_exports, _paginations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _paginations.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _paginations.initialize;
    }
  });
});