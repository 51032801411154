define("web-chat/templates/components/input-tools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WiL9AJm8",
    "block": "[[[10,0],[15,0,[29,[\"input-tools \",[52,[30,1],\"input-tools--has-mode\"]]]],[12],[10,0],[14,0,\"input-tools__content\"],[12],[41,[30,0,[\"rendersEmojis\"]],[[[1,\" \"],[8,[39,1],null,[[\"@visible\",\"@insertText\"],[[28,[37,2],[[30,1],\"emoji\"],null],[30,2]]],null]],[]],null],[13],[13]],[\"@mode\",\"@insertText\"],false,[\"if\",\"input-tools/emoji\",\"eq\"]]",
    "moduleName": "web-chat/templates/components/input-tools.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});