define("web-chat/utils/store/ephemeral", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var store = {};
  var EphemeralStore = {
    setItem: function setItem(key, data) {
      return store[key] = data;
    },
    getItem: function getItem(key) {
      var _store$key;

      return (_store$key = store[key]) !== null && _store$key !== void 0 ? _store$key : null;
    },
    removeItem: function removeItem(key) {
      return delete store[key];
    },
    clear: function clear() {
      store = {};
    }
  };
  var _default = EphemeralStore;
  _exports.default = _default;
});