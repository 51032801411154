define("web-chat/utils/evented", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.waitForEvent = waitForEvent;

  function waitForEvent(evented, event) {
    return new Ember.RSVP.Promise(function (resolve) {
      evented.one(event, resolve);
    });
  }
});