define("web-chat/templates/components/message-action/text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8ckqCLVb",
    "block": "[[[8,[39,0],null,[[\"@forType\",\"@readonly\"],[\"text\",[30,1]]],[[\"default\"],[[[[11,\"form\"],[24,0,\"form\"],[4,[38,1],[\"submit\",[28,[37,2],[[28,[37,3],null,null],[28,[37,4],[[30,2],[30,0,[\"value\"]]],null]],null]],null],[4,[38,5],[[30,0,[\"didInsert\"]]],null],[12],[10,0],[14,0,\"form-field--with-addon\"],[12],[8,[39,6],[[16,\"pattern\",[30,0,[\"requiredPattern\"]]],[16,\"required\",[30,3,[\"required\"]]],[16,\"placeholder\",[30,3,[\"label\"]]],[16,\"readonly\",[30,1]]],[[\"@type\",\"@value\"],[[30,3,[\"type\"]],[30,0,[\"value\"]]]],null],[1,\" \"],[10,0],[14,0,\"form-field__addon\"],[12],[10,\"button\"],[14,0,\"button button--icon button--primary\"],[15,\"disabled\",[52,[30,1],\"disabled\"]],[14,4,\"submit\"],[12],[41,[30,4],[[[1,[28,[35,8],[\"loading-oval-small\"],[[\"class\"],[\"button__spinner\"]]]]],[]],[[[41,[30,5],[[[1,[28,[35,8],[\"icon-checkmark\"],null]]],[]],[[[1,[28,[35,8],[\"icon-angle-right\"],null]]],[]]]],[]]],[13],[13],[13],[13]],[]]]]]],[\"@readonly\",\"@invokeAction\",\"@action\",\"@isInvoking\",\"@validationSuccess\"],false,[\"message-action-base\",\"on\",\"queue\",\"prevent-default\",\"fn\",\"did-insert\",\"input\",\"if\",\"svg-jar\"]]",
    "moduleName": "web-chat/templates/components/message-action/text.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});