define("web-chat/templates/root", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EPzbewZu",
    "block": "[[[10,0],[14,0,\"flash-messages\"],[12],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"flashMessages\",\"queue\"]]],null]],null],null,[[[8,[39,2],null,[[\"@flash\"],[[30,1]]],[[\"default\"],[[[[41,[30,3,[\"componentName\"]],[[[46,[30,3,[\"componentName\"]],null,[[\"content\",\"close\",\"flash\"],[[30,3,[\"content\"]],[30,4],[30,3]]],null]],[]],[[[8,[39,5],null,[[\"@flash\",\"@close\"],[[30,3],[30,4]]],null]],[]]]],[2,3,4]]]]]],[1]],null],[13],[1,\" \"],[46,[28,[37,6],null,null],null,null,null]],[\"f\",\"flashComponent\",\"flash\",\"close\"],false,[\"each\",\"-track-array\",\"flash-message\",\"if\",\"component\",\"default-flash\",\"-outlet\"]]",
    "moduleName": "web-chat/templates/root.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});