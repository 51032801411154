define("web-chat/utils/downa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = render;

  /* eslint-disable */
  var cleanLinesRe = /^\s*/gm;
  var headersRe = /(#+)(.*)/gim;
  var imagesRe = /!\[([^[]+)\]\(([^)]+)\)/gim;
  var linksRe = /\[([^[]+)\]\(([^)]+)\)/gim;
  var boldRe = /(\*\*|__)(.*?)\1/gim;
  var emphasisRe = /(\*|_)(.*?)\1/gim;
  var delRe = /~~(.*?)~~/gim;
  var quoteRe = /:"(.*?)":/gim;
  var blockCodeRe = /```([^```]+)```/gim;
  var inlineCodeRe = /`([^`]+)`/gim;
  var ulListsRe = /\*+(.*)?/gim;
  var olListsRe = /[0-9]+\.(.*)/gim;
  var hrRe = /\n-{5,}/gim;
  var blockQuoteRe = /\n(&gt;|>)(.*)/gim;
  var paragraphsRe = /\n([^\n]+)\n/gim;
  var paragraphsIgnoreRe = /^<\/?(ul|ol|li|h|p|bl|code|table|tr|td)/i;
  var fixUlRe = /<\/ul>\s?<ul>/gim;
  var fixOlRe = /<\/ol>\s<ol>/gim;
  var fixBlockQuoteRe = /<\/blockquote>\s?<blockquote>/gim;
  var rules = {
    cleanLines: {
      regex: cleanLinesRe,
      replacer: function replacer() {
        return '';
      }
    },
    headers: {
      regex: headersRe,
      replacer: function replacer(match, $1, $2) {
        var h = $1.trim().length;
        return "<h".concat(h, ">").concat($2.trim(), "</h").concat(h, ">");
      }
    },
    images: {
      regex: imagesRe,
      replacer: function replacer(match, $1, $2) {
        return "<img src=\"".concat($2, "\" alt=\"").concat($1, "\">");
      }
    },
    links: {
      regex: linksRe,
      replacer: function replacer(match, $1, $2) {
        return "<a href=\"".concat($2, "\" target=\"_blank\" rel=\"noopener noreferrer\">").concat($1, "</a>");
      }
    },
    bold: {
      regex: boldRe,
      replacer: function replacer(match, $1, $2) {
        return "<strong>".concat($2, "</strong>");
      }
    },
    emphasis: {
      regex: emphasisRe,
      replacer: function replacer(match, $1, $2) {
        return "<em>".concat($2, "</em>");
      }
    },
    del: {
      regex: delRe,
      replacer: function replacer(match, $1, $2) {
        return "<del>".concat($1, "</del>");
      }
    },
    quote: {
      regex: quoteRe,
      replacer: function replacer(match, $1, $2) {
        return "<q>".concat($1, "</q>");
      }
    },
    blockCode: {
      regex: blockCodeRe,
      replacer: function replacer(match, $1, $2) {
        return "<pre><code>".concat($1, "</code></pre>");
      }
    },
    inlineCode: {
      regex: inlineCodeRe,
      replacer: function replacer(match, $1, $2) {
        return "<code>".concat($1, "</code>");
      }
    },
    ulLists: {
      regex: ulListsRe,
      replacer: function replacer(match, $1, $2) {
        return "<ul><li>".concat($1.trim(), "</li></ul>");
      }
    },
    olLists: {
      regex: olListsRe,
      replacer: function replacer(match, $1, $2) {
        return "<ol><li>".concat($1.trim(), "</li></ol>");
      }
    },
    hr: {
      regex: hrRe,
      replacer: function replacer() {
        return '<hr />';
      }
    },
    blockQuote: {
      regex: blockQuoteRe,
      replacer: function replacer($match, $1, $2) {
        return "\n<blockquote>".concat($2, "</blockquote>");
      }
    },
    paragraphs: {
      regex: paragraphsRe,
      replacer: function replacer($match, $1) {
        var trimmed = $1.trim();

        if (paragraphsIgnoreRe.test(trimmed)) {
          return "\n".concat(trimmed, "\n");
        }

        return "\n<p>".concat(trimmed, "</p>\n");
      }
    },
    fixUl: {
      regex: fixUlRe,
      replacer: function replacer($match, $1) {
        return '';
      }
    },
    fixOl: {
      regex: fixOlRe,
      replacer: function replacer($match, $1) {
        return '';
      }
    },
    fixBlockquote: {
      regex: fixBlockQuoteRe,
      replacer: function replacer($match, $1) {
        return '';
      }
    }
  };
  /**
   * @param  {String} markdown A string of Markdown content
   * @returns {String} A HTML formatted string
   */

  function render(markdown) {
    Object.keys(rules).forEach(function (key) {
      markdown = markdown.replace(rules[key].regex, rules[key].replacer);
    });
    return markdown.trim();
  }
});