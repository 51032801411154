define("web-chat/services/sync", ["exports", "web-chat/services/-private/sync"], function (_exports, _sync) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sync.default;
    }
  });
});